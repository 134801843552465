import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../../style/theme";
import { HorizontalLine } from "../Lines";
import Section from "../Section";
import TypeformEmbed from "../TypeformEmbed";
import SliceTitle from "./SliceTitle";

const TypeFormSlice = ({ slice, page, id }) => {
  const { title, typeformId, position = "right" } = slice;
  const classes = useStyles({ position: position });

  return (
    <Section grid className={classes.section} id={id}>
      <SliceTitle className={classes.title}>{title}</SliceTitle>
      <div className={classes.content}>
        <div className={classes.drawerBody}>
          <TypeformEmbed id={typeformId} open={true} />
        </div>
      </div>
      <HorizontalLine extend position="bottom" />
    </Section>
  );
};

const useStyles = createUseStyles({
  section: {
    position: "relative",
  },
  title: {
    gridColumn: "1 / span 4",
    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 3",
    },
  },
  content: {
    marginBottom: theme.section.marginBottom.sm,
    gridColumn: "1 / span 4",
    // aspectRatio: 0.5,
    width: "100%",
    height: "calc(100vh - 100px)",
    [theme.breakpoints.up("md")]: {
      // aspectRatio: 1,
      height: "calc(100vh - 124px)",
      width: "100%",
      gridColumn: ({ position }) => {
        const listPosition = {
          left: "1 / span 4",
          center: "2 /span 5",
          right: "4 / span 4",
        };
        return listPosition[position];
      },
      gridRow: 2,
      marginBottom: theme.section.marginBottom.md,
    },
  },
  drawerBody: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    overflow: "auto",
    position: "relative",
    height: "100%",
    overflow: "hidden",
    "&.empty:after": {
      display: "none",
    },
    // padding: [theme.gutter.sm],
    paddingTop: 0,
    // marginTop: -8,
    [theme.breakpoints.up("md")]: {
      //   padding: [theme.gutter.md],
      paddingTop: 0,
    },
  },
});

export default TypeFormSlice;
