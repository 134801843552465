import React from "react";
import { Helmet } from "react-helmet-async";

const MetaPixel = () => {
  return (
    <Helmet>
      {/* Facebook Pixel Script */}
      <script id="facebook-pixel-script">{`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${process.env.FACEBOOK_PIXEL_ID}');
      fbq('track', 'PageView');
      `}</script>
      <noscript
        id="facebook-pixel-image"
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${process.env.FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"
          />`,
        }}
      />

      {/* LinkedIn Insight Tag */}
      <script type="text/javascript">{`
        _linkedin_partner_id = "3255250";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript"; b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        })(window.lintrk);
      `}</script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none;" alt=""
          src="https://px.ads.linkedin.com/collect/?pid=3255250&fmt=gif" />`,
        }}
      />
    </Helmet>
  );
};

export default MetaPixel;
